import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core'
import { RuntimeService } from 'src/app/service/runtime.service'
import { reflow } from 'src/tools/html.tools'
import { throttle } from 'lodash-es'

@Directive({
  selector: '[fitText]',
  standalone: true
})
export class FitTextDirective implements AfterViewInit, OnDestroy {
  protected resizeObserver!: ResizeObserver
  protected throttledUpdate = throttle(this.update.bind(this), 250,{ leading: true, trailing: true })

  @Input() wrap: boolean = false

  constructor (
    protected elementRef: ElementRef,
    protected runtimeService: RuntimeService,
  ) {}

  ngAfterViewInit () {
    if (this.runtimeService.isServer()){
      return
    }

    window.addEventListener('resize', this.throttledUpdate.bind(this))

    this.resizeObserver = new ResizeObserver(this.throttledUpdate.bind(this))
    this.resizeObserver.observe(this.elementRef.nativeElement)

    this.update()
  }

  protected update () {
    const _el = this.elementRef.nativeElement as HTMLElement
    _el.style.removeProperty('font-size')
    const isFontSize = parseFloat(window.getComputedStyle(_el).fontSize.replace('px', ''))
    _el.style.setProperty('max-width', '100%')
    reflow(_el)
    const shouldWidth = _el.clientWidth
    if (this.wrap) {
      _el.style.setProperty('white-space', 'wrap')
      _el.style.setProperty('width', 'fit-content')
    } else {
      _el.style.setProperty('white-space', 'nowrap')
      _el.style.setProperty('width', 'max-content')
    }
    _el.style.setProperty('max-width', '')
    reflow(_el)
    const isWidth = _el.clientWidth


    if (isWidth !== shouldWidth) {
      const fontSize = (shouldWidth/isWidth) * isFontSize
      if (fontSize < isFontSize) {
        _el.style.setProperty('font-size', fontSize + 'px')
      }
    }

    _el.style.setProperty('width', '')
    _el.style.setProperty('white-space', '')
  }

  ngOnDestroy () {
    this.resizeObserver?.disconnect()

    if (this.runtimeService.isClient()){
      window.removeEventListener('resize', this.throttledUpdate.bind(this))
    }
  }
}
